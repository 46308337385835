@media  screen and (min-width: 1024px) {
    .logoRepertoireVert {
        display:none;
    }
}

@media screen and (max-width: 1024px) and (min-height:601px) {
    .container-navbar-react {
        padding: 2% 7% 2% 7%;
    }
}