/*élément du menu de navigation supérieur */

.div-navbar a {
  color: white;
  text-decoration: none;
}

.div-navbar a:hover {
  font-weight: bold;
}

div.img {
  width: 21%;
}

.div-logo-navbar {
  position: relative;
  left: 1.5vw;
  top: 1vh;
  width: 11.21vw;
  z-index: 100;
}

.navbarHomeButton,
.navbarHomeButton:hover {
  position: absolute;
  top: 2.44vw;
  left: 19.96vw;
  color: white;
}

.navbarHomeButton {
  position: absolute;
  top: 2.44vw;
  left: 19.96vw;
}

.navBarServicesButton {
  position: absolute;
  top: 2.44vw;
  left: 34.95vw;
  color: white;
}

.navBarQuiSommesNousButton {
  position: absolute;
  top: 2.44vw;
  left: 25.27vw;
  color: white;
}

.navbarActualitesButton {
  position: absolute;
  top: 2.44vw;
  left: 41.15vw;
  color: white;
}

.navbarNouveautesButton {
  position: absolute;
  top: 2.44vw;
  left: 48.25vw;
  color: white;
}

.navbarCommunauteButton {
  position: absolute;
  top: 2.44vw;
  left: 56vw;
  color: white;
}

.navbarShopButton {
  position: absolute;
  top: 2.44vw;
  left: 64vw;
  color: white;
}

.navbarSearchButton {
  position: absolute;
  top: 2.44vw;
  left: 68vw;
  color: white;

  & img {
    width: 27px;
    margin-top: -10px;
  }
}

.carresOrange {
  position: absolute;
  top: 1.22vw;
  left: 91.5vw;
}

.navBarSinscrireButton {
  position: absolute;
  top: 2.44vw;
  left: 73vw;
  color: white;
}

.navbarInscriptionButton {
  position: absolute;
  top: 2.44vw;
  left: 72.5vw;
}

.separator {
  position: absolute;
  top: 2.44vw;
  left: 77.25vw;
}

.separatorLogout {
  position: absolute;
  top: 2.44vw;
  left: 77.5vw;
}

.navbarLoginButton {
  position: absolute;
  top: 2.44vw;
  left: 78vw;
}

.navbarLoginButton:hover {
  cursor: pointer;
}

.navbarLogoutButton {
  position: absolute;
  top: 2.46vw;
  left: 78vw;
}

.navbarLangueButton {
  position: absolute;
  top: 2.4vw;
  right: 9.5vw;
}

/* Style The Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  font-size: 0.93vw;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  z-index: 1;
  padding: 0.3vh 0.5vw;
  border-radius: 5px;
}

.dropdown-content a {
  color: black;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  font-weight: normal;
  color: #618226;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: transparent;
}

// .navbarLangueButton select {
//   background-color: transparent;
//   border: none;
//   color: white;
//   font-size: 0.93vw;
// }

// .navbarLangueButton option {
//   color: black;
// }

.navbarAccountButton {
  position: absolute;
  top: 2.44vw;
  left: 72vw;
  color: white;
}

@media screen and (min-width: 1024px) {
  .img {
    display:none;
  }
}