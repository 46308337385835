.image1 {
    color:white;
    position:relative;
    &-title {
        position: absolute;
        top:50%;
        left:5%;
        font-weight: bold;;
    }
}
.programmes2 {
    display: flex;
    flex-flow: row wrap;
}
.div_img {
    width: 23%;
    margin: 0.7%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.programme-image-1 {
    display: block;
    width:100%;
    filter: blur(1px) drop-shadow(0 0 0 black) brightness(0.5);
}
.programmes_title {
    color: white;
    position: absolute;
    text-align: center;
    font-weight: bold;
    font-size: 3vh;
}