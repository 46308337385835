div.container-navbar-react {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.container-navbar-logo {
    width: 15%;

    a {
        width: 100%;

        .image-logo {
            width: 100%;
        }
    }
}

.container-navbar-global {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.container-navbar-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    nav {
        height: 64px;
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        ul {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            margin: 0;
            padding: 0;

            li {
                display: flex;
                justify-content: center;
                align-items: center;

                list-style: none;
                width: 14%;

                a {
                    text-decoration: none;
                    text-align: center;
                    color: white;

                    font-size: 0.93vw;

                    &:hover {
                        font-weight: bold;
                    }
                }
            }

            .navbar-menu-quisommenous {
                width: 15%;
            }
        }
    }
}

.container-navbar-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 15%;
    margin-left: 1.5%;

    .navbar-menu-logon,
    .navbar-menu-logout,
    .navbar-menu-moncompte,
    .navbar-menu-inscription {
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            text-decoration: none;
            text-align: center;
            color: white;
            font-size: 0.93vw;
            width: 100%;

            &:hover {
                text-decoration: none;
                font-weight: bold;
                color: white;
            }
        }
    }

    .navbar-menu-logon {
        width: 45%;
    }

    .navbar-menu-inscription {
        width: 31%;
    }

    .navbar-menu-logout {
        width: 52%;
    }

    .navbar-menu-moncompte {
        width: 46%;
    }

    .navBar-login-separator {
        width: 2%;
        color: white;
        font-size: 0.93vw;
        text-align: center;
    }
}

.container-navbar-language {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 5%;

}

.navbar-changelanguage-button {
    background-color: transparent;
    color: white;
    font-size: 0.93vw;

    &:hover {
        text-decoration: none;
        font-weight: bold;
        color: white;
    }
}

@media screen and (max-width: 1024px) {
    div.container-navbar-global {
        display: none;
    }
}